import React, { PureComponent, RefObject, MouseEvent } from 'react'
import styled from 'styled-components'
import ScrollIntoView from 'scroll-into-view'
import Typography from '@material-ui/core/Typography'
import { WindowLocation } from '@reach/router'
import Slider from 'react-slick'

import Theme from '../theme'
import Layout from '../components/layout'
import Modal from '../components/modal'
import TextualCarousel, { DisplayItem } from '../components/textualCarousel'
import Section, {SubSection, Header, SubHeader, Divider} from '../components/section/section'

// Icons
import SecurityIcon from '../images/icons/security.svg'
import CleaningIcon from '../images/icons/broom.svg'
import CheckInIcon from '../images/icons/check-in-staff.svg'
import KitchenIcon from '../images/icons/stove.svg'
import FurnitureIcon from '../images/icons/sofa.svg'
import CandyBarIcon from '../images/icons/candybar.svg'
import RaisedStageIcon from '../images/icons/box-shadow.svg'
import ConsoleIcon from '../images/icons/tune-vertical.svg' 
import WedgeSpeakerIcon from '../images/icons/wedge-speaker.svg'
import SubwooferIcon from '../images/icons/subwoofer.svg'
import IEMIcon from '../images/icons/iem.svg'
import WirelessMicrophone from '../images/icons/wireless-microphone.svg'
import InstrumentMicrophone from '../images/icons/instrument-microphone.svg'
import AudixMicrophoneIcon from '../images/icons/audix-microphone.svg'

import DJIcon from '../images/icons/disc-player.svg'
import MicrophoneIcon from '../images/icons/microphone-variant-black.svg'
import ProjectorIcon from '../images/icons/projector.svg'

// LED screen images
import ScreenA from '../images/services/led-screen-a.jpg'
import ScreenB from '../images/services/led-screen-b.jpg'
import ScreenC from '../images/services/led-screen-c.jpg'
import ScreenD from '../images/services/led-screen-d.jpg'

const DarkSectionHeader = styled.div<{isLeft?: boolean, isRight?: boolean}>`
    background: ${Theme.section.dark.background};
    border: 2px solid white;
    position: relative;
    left: ${props => props.isLeft ? '-2px' : 'unset'};
    right: ${props => props.isRight ? 'calc(-60% - 2px)' : 'unset'};
    top: 0;
    padding: 40px;
    width: 40%;
    z-index: 1;
    box-shadow: 0px 2px 18px -2px;

    &:after {
        content: '';
        height: 2px;
        background: white;
        width: 100vw;
        z-index: 0;
        position: absolute;
        top: calc(50% - 2px);
        left: ${props => props.isLeft ? '100%' : 'unset'};
        right: ${props => props.isRight ? '100%' : 'unset'};
    }

    @media(max-width: 768px) {
        width: 102vw;
        left: ${props => props.isLeft ? '-2px' : 'unset'};
        right: ${props => props.isRight ? '2px' : 'unset'};
    }
`

const ImageGrid = styled.div`
    display: grid;
    grid-template-areas: 'a a a' 'b c c' 'd d d';
    grid-gap: 1rem;
    grid-template-columns: 42.5% calc(30.25vw * 9/16);
    width: 40vw;
    margin: 0 auto;

    @media(max-width: 840px) {
        width: 100%;
    }
`

const SliderWrapper = styled(Slider)`
  .slick-list {
    .slick-track {
      .slick-slide {
        display: flex;
        justify-content: center;
      }
    }
  }
`

const addOnServices = [
    {
        icon: 'local_bar', 
        title: 'Bartenders',
        description: 'Seasoned, friendly, and efficient bartenders to serve a multitude of adult or kid-friendly drinks for your guests.',
        height: '200px'
    },
    {
        icon: SecurityIcon,
        title: 'Security',
        description: 'Trusted security team to ensure a safe and fun environment.',
        isGoogleIcon: false,
        height: '200px'
    },
    {
        icon: CheckInIcon,
        title: 'Check-in Staff',
        description: 'Professional, friendly, and experienced staff to provide a smooth check-in process for events with a guest list.',
        isGoogleIcon: false,
        height: '200px'
    },
    {
        icon: CleaningIcon,
        title: 'Cleaning Staff',
        description: 'Efficient and attentive cleaning personnel available throughout and following the event.',
        isGoogleIcon: false,
        height: '200px'
    },
    {
        icon: 'tune', 
        title: 'Sound Engineer',
        description: 'Knowledgeable sound engineer well versed in all the technology we provide in our space, including DJ equipment, monitors, speaker systems, and projector.',
        height: '200px'
    },
    {
        icon: KitchenIcon,
        title: 'Kitchen Facility',
        description: 'Access to large kitchen area with hot plates for food prep and storage.',
        isGoogleIcon: false,
        height: '200px'
    },
    {
        icon: 'tv',
        title: 'LED Screens',
        description: 'Prime advertising real estate in the heart of Hollywood. Two large LED screens facing out to Hollywood Blvd. directly across from El Capitan Theatre, and a marquee ribbon screen above the entrance to the event space.',
        height: '200px'
    },
    {
        icon: CandyBarIcon,
        title: 'Custom Candy Bars',
        description: 'Our unique partnership with Sweet! Hollywood allows you to create candy bars from a decadent list of ingredients, along with custom designed packaging.',
        isGoogleIcon: false,
        height: '200px'
    },
    {
        icon: FurnitureIcon,
        title: 'Rearranging/removal of Furniture',
        description: 'Furniture can be adjusted to accommodate the needs of your specific event.',
        isGoogleIcon: false,
        height: '200px'
    }
]

const equipment = {
    audioVideoList: [
        {
            icon: 'tv',
            title: 'LED Screens',
        },
        {
            icon: 'speaker',
            title: 'State of the Art Peavey Sound System',
        },
        {
            icon: DJIcon,
            title: 'DJ Equipment',
            isGoogleIcon: false
        },
        {
            icon: MicrophoneIcon,
            title: 'Microphones',
            isGoogleIcon: false
        },
        {
            icon: ProjectorIcon,
            title: 'Projector and 135” Pull Down Screen',
            isGoogleIcon: false
        },
        {
            icon: 'tv',
            title: '70\' HDTV'
        }
    ],
    stageList: [
        {
            icon: RaisedStageIcon,
            title: 'Raised Stage Platform',
            isGoogleIcon: false
        },
        {
            icon: 'settings_input_component',
            title: 'Stands and cabling to Accommodate Full Band',
        }
    ],
    frontOfHouseList: [
        {
            icon: ConsoleIcon,
            title: 'Console: Behringer X32 Core with s32 I/O Box',
            description: 'Controlled by an Apple iPad',
            isGoogleIcon: false
        },
        {
            icon: 'speaker',
            title: 'Mains: Peavey RBN 112 Tops & RBN 118 Cabs',
        },
        {
            icon: 'speaker',
            title: 'Delays: Peavey RBN 110 Tops',
            description: '1 per side for both',
        }
    ],
    monitorsList: [
        {
            icon: ConsoleIcon,
            title: 'Console',
            description: 'Controlled from the front of the house',
            isGoogleIcon: false,
            large: true
        },
        {
            icon: WedgeSpeakerIcon,
            title: 'Wedges: 2 Peavey RBN 112’s',
            isGoogleIcon: false,
            large: true
        },
        {
            icon: SubwooferIcon,
            title: 'Drum Fill: Peavey RBN 215',
            isGoogleIcon: false,
            large: true
        },
        {
            icon: IEMIcon,
            title: 'IEM’s: 5 Sennheiser EW 300 G3 series',
            isGoogleIcon: false,
            large: true
        }
    ],
    microphonesList: [
        {
            icon: WirelessMicrophone,
            title: '1 Shure ULX-D wireless microphone',
            isGoogleIcon: false,
            large: true
        },
        {
            icon: MicrophoneIcon,
            title: '4 Shure Beta 58\'s',
            isGoogleIcon: false,
            large: true
        },
        {
            icon: InstrumentMicrophone,
            title: '4 Shure SM57\'s',
            isGoogleIcon: false,
            large: true
        },
        {
            icon: AudixMicrophoneIcon,
            title: '2 Audix D4\'s',
            isGoogleIcon: false,
            large: true
        }
    ],
}

type Props = {
    location: WindowLocation
}

type State = {
    toggledImage: string | null,
    toggledSection: string
}

class Services extends PureComponent<Props, State> {
    private equipmentSection: RefObject<HTMLDivElement>
    private audioVideoSection: RefObject<HTMLDivElement>
    private stageSection: RefObject<HTMLDivElement>
    private frontOfHouseSection: RefObject<HTMLDivElement>
    private monitorsSection: RefObject<HTMLDivElement>
    private micorphones: RefObject<HTMLDivElement>

    constructor(props: Props) {
        super(props)

        this.toggleImage = this.toggleImage.bind(this)
        this.toggleSection = this.toggleSection.bind(this)

        this.equipmentSection = React.createRef()
        this.audioVideoSection = React.createRef()
        this.stageSection = React.createRef()
        this.frontOfHouseSection = React.createRef()
        this.monitorsSection = React.createRef()
        this.micorphones = React.createRef()

        this.state = {
            toggledImage: null,
            toggledSection: 'audioVideo'
        }
    }

    toggleImage = (event: MouseEvent) => {
        this.setState({
            toggledImage: event.currentTarget ? event.currentTarget.src : null
        })
    }

    toggleSection = (event: MouseEvent) => {
        if (typeof window !== 'undefined' && window.innerWidth <= 768) {
            this.setState({
                toggledSection: event.currentTarget.dataset.section
            })
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevState.toggledSection !== this.state.toggledSection) {
            switch (this.state.toggledSection) {
                case 'audio-video':
                    ScrollIntoView(this.audioVideoSection.current, {align: {top: 0, topOffset: 56}});
                    break
                case 'stage':
                    ScrollIntoView(this.stageSection.current, {align: {top: 0, topOffset: 56}})
                    break
                case 'front-of-house':
                    ScrollIntoView(this.frontOfHouseSection.current, {align: {top: 0, topOffset: 56}})
                    break
                case 'monitors':
                    ScrollIntoView(this.monitorsSection.current, {align: {top: 0, topOffset: 56}})
                    break
                default:
                break
            }
        }
    }

    render() {
        const modalizedImage = this.state.toggledImage ? (
            <Modal handleClose={this.toggleImage}>
                <img 
                    src={this.state.toggledImage} 
                    alt="Toggled"
                    style={{
                        maxWidth: '99vw', 
                        maxHeight: '90vh',
                        padding: '40px'
                    }}/>
            </Modal>
        ) : null

        const audioVideoItems = equipment.audioVideoList.map(item => 
            <DisplayItem 
                key={item.title}
                {...item} 
                color="white" />
        )

        const stageItems = equipment.stageList.map(item => 
            <DisplayItem 
                key={item.title}
                {...item} 
                color="white" />
        )

        const frontOfHouseItems = equipment.frontOfHouseList.map(item =>
            <DisplayItem 
                key={item.title}
                {...item} 
                color="white" />
        )

        const monitorItems = equipment.monitorsList.map(item => 
            <DisplayItem 
                key={item.title}
                {...item}
                color="white" />
        )

        const microphoneItems = equipment.microphonesList.map(item => 
            <DisplayItem 
                key={item.title}
                {...item} 
                color="white" />
        )

        const isMobile = typeof window !== 'undefined' && window.innerWidth <= 768

        const sliderSettings = {
            dots: false,
            infinite: true,
            autoplaySpeed: 3000,
            autoplay: true,
            arrows: false,
            slidesToShow: isMobile ? 1 : 3,
            slidesToScroll: 1
        }

        /**
         * Hard coded style for mobile toggling
         * 
         * style={{display: !isMobile ? 'flex' : this.state.toggledSection === 'audio-video' ? 'flex' : 'none'}}
         */

        return(
            <Layout location={this.props.location}>
                <Section width="100%" margin="0" flexDirection="column" overflow="hidden">
                    <Section 
                        id="add-on-services"
                        width="100%" 
                        margin="0" 
                        padding="40px 0"
                        background="whitesmoke">
                        <SubSection>
                            <Header title="Add-On Services"/>
                            <SubHeader>
                                Regardless of the size or type of event you’re
                                planning, we have all the elements to make it
                                successful. From staffing to lighting to audio/visual, The Venue of Hollywood can make it
                                happen. Make sure to inquire regarding any
                                specific ideas you might have when it comes
                                to your particular event.
                            </SubHeader>
                            <Divider themeColor={Theme.palette.primary.main}/>
                            <div>
                                <SliderWrapper {...sliderSettings}>
                                    {
                                        addOnServices.map(item => (
                                            <DisplayItem 
                                                key={item.title}
                                                icon={item.icon}
                                                title={item.title}
                                                description={item.description}
                                                isGoogleIcon={item.isGoogleIcon}
                                                height={item.height} />
                                        ))
                                    }
                                </SliderWrapper>
                            </div>
                        </SubSection>
                    </Section>
                    <Section 
                        id="equipment"
                        width="100%" 
                        margin="0" 
                        padding="40px 0"
                        flexDirection="column" 
                        themeColor={Theme.palette.primary.main}>
                        <SubSection>
                            <Header title="Equipment" themeColor="white" />
                            <Divider/>
                        </SubSection>
                        <Section flexDirection="column">
                            <DarkSectionHeader isLeft={true} onClick={this.toggleSection} data-section="audio-video" ref={this.audioVideoSection}>
                                <Header title="Audio/Video Technology" margin="0 0 20px 0" themeColor="white" />
                                <SubHeader 
                                    maxWidth="100%" 
                                    themeColor="white">
                                    The Venue of Hollywood features impressive audio/visual equipment, including massive LED screens with the option to project out to Hollywood Blvd. or into the event space, a state of the art Peavey sound system, DJ equipment, microphones, projector and screen, and HDTV.
                                </SubHeader>
                            </DarkSectionHeader>
                            <Section margin="60px" flexWrap="wrap" justifyContent="center">
                                {audioVideoItems}
                            </Section>
                            <DarkSectionHeader isRight={true} onClick={this.toggleSection} data-section="stage" ref={this.stageSection}>
                                <Header title="Stage" margin="0 0 20px 0" themeColor="white" />
                                <SubHeader 
                                    maxWidth="100%" 
                                    themeColor="white">
                                    Raised stage platform with the ability to provide ample space for a variety of performance types, from solo performer to full band. 
                                </SubHeader>
                            </DarkSectionHeader>
                            <Section margin="60px" flexWrap="wrap" justifyContent="center">
                                {stageItems}
                            </Section>
                            <DarkSectionHeader isLeft={true} onClick={this.toggleSection} data-section="front-of-house" ref={this.frontOfHouseSection}>
                                <Header title="Front of House" margin="0" themeColor="white"/>
                            </DarkSectionHeader>
                            <Section margin="60px" flexWrap="wrap" justifyContent="center">
                                {frontOfHouseItems}
                            </Section>
                            <DarkSectionHeader isRight={true} onClick={this.toggleSection} data-section="monitors" ref={this.monitorsSection}>
                                <Header title="Monitors" margin="0" themeColor="white"/>
                            </DarkSectionHeader>
                            <Section margin="60px" flexWrap="wrap" justifyContent="center">
                                {monitorItems}
                            </Section>
                            <DarkSectionHeader isLeft={true} onClick={this.toggleSection} data-section="microphones" ref={this.micorphones}>
                                <Header title="Microphones" margin="0" themeColor="white"/>
                            </DarkSectionHeader>
                            <Section margin="60px" flexWrap="wrap" justifyContent="center">
                                {microphoneItems}
                            </Section>
                        </Section>
                    </Section>
                    <Section 
                        id="led-screens"
                        width="100%" 
                        margin="0" 
                        padding="40px 0 80px 0"
                        flexDirection="column" 
                        background="whitesmoke">
                        <SubSection margin="0 auto">
                            <Header title="LED Screens"/>
                            <SubHeader>
                                There are two main LED screens for advertising
                                and a marquee ribbon screen above the
                                main entrance to the event space that faces
                                Hollywood Blvd., right across the street from
                                El Capitan Theatre. The screens bookend the
                                Hard Rock Cafe and the Historic Grauman’s/TCL
                                Chinese Theatre marquees and are right next
                                to the main entrance to the Dolby Theatre.
                                This is prime advertising real estate right in
                                the heart of Hollywood.
                            </SubHeader>
                            <Divider themeColor={Theme.palette.primary.main}/>
                            <SubSection>
                                <ImageGrid>
                                    <ScreenBlock 
                                        gridArea="a" 
                                        dimensions="20 ft W x 12 ft H" 
                                        caption="This screen projects directly out to Hollywood Blvd from the north side of the street, to the right of the Hard Rock Cafe sign."
                                        image={ScreenA} 
                                        clickHandler={this.toggleImage}/>
                                    <ScreenBlock 
                                        gridArea="b" 
                                        dimensions="6 ft W x 12 ft H" 
                                        caption="This screen projects out from the north side of Hollywood Blvd, and is connected on the corner to Screen A."
                                        image={ScreenB} 
                                        clickHandler={this.toggleImage}/>
                                    <ScreenBlock 
                                        gridArea="c" 
                                        dimensions="16 ft W x 10 ft H"
                                        caption="This screen projects directly out to Hollywood Blvd from the north side of the street, to the left of the Hard Rock Cafe sign." 
                                        image={ScreenC} 
                                        clickHandler={this.toggleImage}/>
                                    <ScreenBlock 
                                        gridArea="d" 
                                        dimensions="6 ft W x 12 ft H" 
                                        caption="These screens are A & B folded back inside of the space to project inward. When they are folded back, it allows for the amazing view of Hollywood Blvd. from inside of the space."
                                        image={ScreenD} 
                                        clickHandler={this.toggleImage}/>
                                </ImageGrid>
                            </SubSection>
                        </SubSection>
                    </Section>
                    {modalizedImage}
                </Section>
            </Layout>
        )
    }
}

const ScreenBlock = ({gridArea, dimensions, caption, image, clickHandler} : {gridArea: string, dimensions: string, caption: string, image: string, clickHandler: (event: MouseEvent) => void}) => {
    const Block = styled.div`
        display: flex;
        flex-direction: column;
        grid-area: ${gridArea};
        min-height: 250px;
        box-shadow: 0px 2px 10px -4px black;
        position: relative;
    `

    const ScreenImage = styled.img`
        margin: 0;
        display: flex;
        align-self: center;
        min-height: inherit;
        object-fit: cover;
        height: fill-available;
        width: fill-available;

        &:hover {
            cursor: pointer;
        }
    `

    const ImageCaption = styled(Typography)`
        &&& {
            padding: 10px;
            background: rgba(0,0,0,0.9);
            color: white;
        }
    `

    const ScreenCaption = styled(Typography)`
        &&& {
            padding: 10px;
            border-radius: 0 0 3px 3px;
            text-align: center;
            position: relative;
            color: white;
            background: ${Theme.palette.primary.main};
        }
    `

    return(
        <Block>
            <ScreenImage src={image} onClick={clickHandler}/>
            <ImageCaption>
                {caption}
            </ImageCaption>
            <ScreenCaption 
                variant="body1">
                {dimensions}
            </ScreenCaption>
        </Block>
    )
}

export default Services